.namespace-detail {
  .pf-c-toolbar__content {
    padding-left: 0;
  }
}

.delete-namespace-modal-message {
  padding-bottom: var(--pf-global--spacer--md);
}

.hub-namespace-page-controls {
  display: flex;
  align-items: center;
}

.hub-c-alert-namespace {
  position: fixed;
  right: 5px;
  top: 80px;
  z-index: 300;
}
