.body {
  background-color: white;
  padding: 16px;
}

.clickable {
  cursor: pointer;
}

#page-sidebar > div {
  padding-top: 0;
}

#page-sidebar {
  border-top: solid var(--pf-global--BorderWidth--sm)
    var(--pf-global--palette--black-800);
}

section.pf-c-nav__section.nav-title > h2 {
  height: 50px;
  font-size: var(--pf-c-nav__link--FontSize);
  font-weight: var(--pf-c-nav__link--FontWeight);
}

// this was getting applied globally when it was in the collection-info.scss file
// which means other components now rely on it, so I'm just going move it here
// so that it's explicitly setting these styles globally.
pre {
  background-color: #f8f8f8;
  border: 1px solid #e6e9e9;
  font-family: var(--pf-global--FontFamily--monospace);
  display: block;
  padding: 10px;

  // Patternfly does something that makes it so that fonts don't get inherited
  // from the parent so it has to be overridden at every level.
  code {
    font-family: var(--pf-global--FontFamily--monospace);
  }
}

// Patternfly expects the root Page component to be the height of the browser window
body,
#root {
  height: 100%;
}

// list screen toolbars
// FIXME: merge these 2; also with search, namespace-list, namespace-details, hub-toolbar-wrapper
.hub-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.hub-list-toolbar {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .pf-c-toolbar__content {
    padding-left: 0;
  }
}
