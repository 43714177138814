$breakpoint-md: 1000px;

.image {
  padding-right: 16px;
}

.title-box {
  display: flex;
  align-items: center;
}

.column-section {
  display: flex;
  justify-content: space-between;

  .header-right {
    align-items: right;
  }
}

.install-version-column {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
}

.install-version-dropdown {
  width: 300px;
}

.last-updated {
  color: grey;
}

.background {
  background-color: white;
  padding: 0 24px;
}

.placeholder {
  height: 24px;
}

.breadcrumb-container {
  padding-top: 10px;
  padding-bottom: 10px;
}

.header-bottom {
  padding-top: 10px;
}

.versions-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: var(--pf-global--spacer--md);
}

.dependent-collections-alert-list {
  margin-top: var(--pf-global--spacer--sm);
}

.hub-tab-link-container {
  display: flex;
  margin-top: 10px;

  @media (max-width: $breakpoint-md) {
    flex-direction: column-reverse;
  }

  .tabs {
    flex-grow: 0;
  }

  // Tabs isDisabled
  .pf-c-tabs__item.disabled .pf-c-tabs__link {
    cursor: not-allowed;
  }

  .links {
    @media (min-width: $breakpoint-md) {
      justify-content: flex-end;
    }

    @media (max-width: $breakpoint-md) {
      margin-bottom: 10px;
    }

    flex-grow: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    div {
      @media (min-width: $breakpoint-md) {
        margin-left: 20px;
      }

      @media (max-width: $breakpoint-md) {
        margin-right: 20px;
      }
    }
  }
}
