.search-page {
  height: 100%;
  display: flex;
  flex-direction: column;

  .hub-toolbar-wrapper .toolbar .hub-pagination-container .card-list-switcher {
    margin-right: 24px;
  }

  .header {
    border-bottom: 1px solid #d8d8d8;
  }

  .pf-c-toolbar__content {
    padding-left: 0;
  }

  .collection-container {
    padding-top: 24px;
    flex-grow: 1;

    .cards {
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;

      .card {
        margin-left: 24px;
        margin-bottom: 24px;
      }
    }

    .list-container {
      width: 100%;
    }

    .list {
      margin-bottom: 24px;
      margin-left: 24px;
      margin-right: 24px;
      padding-top: 16px;
      padding-bottom: 16px;

      // Hack to hide the annoying line at the top of the data list.
      overflow: hidden;

      .data-list {
        margin-top: -17px;
        margin-bottom: -17px;
      }
    }

    .empty {
      flex-grow: 1;
    }
  }

  .footer {
    border-top: 1px solid #d8d8d8;
    flex-shrink: 0;
  }
}
